.loading-icon-container {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  opacity: 0.3;
  
  div.card.spinner-container {
    min-height: 50px;
    margin-top: 30px;
    border: none;
    background: transparent;
    text-align: center;
  }
  
  .loading-title {
    padding-bottom: 25px;
  }

  .spinner-gray {
    width: 100px;
    height: auto;
    max-width: 100%;
  }
}
