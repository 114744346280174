@import './constants/base_constants.scss';


%custom-dropdown-style {
    color: color(text, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid color(line, 1);

    &:hover,
    &:active {
        background-color: white !important;
        border-color: color(line, 1) !important;
        color: color(text, 1) !important;
    }
}

.hs-dashboard-filter-time {
    .hs-dashboard-time-toggle {
        min-width: 130px;
        height: 30px;
        @extend %custom-dropdown-style;
        justify-content: space-between;
    }

    .dropdown-menu {
        min-width: 100px;
    }
}

.hs-homepage-payment, .hs-homepage-profile {
    &-toggle {
        width: 100%;
        height: 100%;
        @extend %custom-dropdown-style;
    }

    .dropdown-menu {
        // margin-top: 20px;
        min-width: 130px;
    }
    .dropdown-item.active, .dropdown-item:active {
        background-color: transparent;
    }

    .dropdown-item:hover, .dropdown-item:focus {
        background-color: transparent;
    }
}