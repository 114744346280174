@import "bootstrap/scss/bootstrap.scss";
@import "styles/custom-bootstrap.scss";
@import "./styles/constants/base_constants";
@import url("./icons/font-awesome/css/all.min.css");
@import url("./styles/animate/animate.min.css");

@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: PingFang;
  font-weight: 300;
  src: url("./fonts/PingFang/PingFang-SC-Light.ttf") format("truetype");
}
@font-face {
  font-family: PingFang;
  font-weight: 400;
  src: url("./fonts/PingFang/PingFang-SC-Regular.ttf") format("truetype");
}
@font-face {
  font-family: PingFang;
  font-weight: 600;
  src: url("./fonts/PingFang/PingFang-SC-Bold.ttf") format("truetype");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 300;
  src: url("./fonts/SourceSansPro/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 400;
  src: url("./fonts/SourceSansPro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-style: italic;
  src: url("./fonts/SourceSansPro/SourceSansPro-Italic.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 500;
  src: url("./fonts/SourceSansPro/SourceSansPro-Semibold.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 600;
  src: url("./fonts/SourceSansPro/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 800;
  src: url("./fonts/SourceSansPro/SourceSansPro-Black.ttf");
}

@font-face {
  font-family: "PreferredFont";
  src: local("Helvetica Neue");
}

.pingFang-font {
  font-family: PingFang, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.sourceSansPro-font {
  font-family: SourceSansPro, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  margin: 0;
  font-family: Poppins, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.hs-story-description,
.hs-product-description {
  a {
    color: color(blue, 40);
  }
  p {
    width: 100%;
    img {
      max-width: 100%;
      // margin: 0 auto;
    }
  }
}

.hs-payment-icon-container {
  svg {
    margin-bottom: 20px;
    margin-right: 10px;
  }
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    color: transparent;
    /* Chrome Safari */
  }

  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.btn-brand-primary {
  color: #27458f;
}

.react-tel-input.hs-phone-input {
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;

  div,
  input.form-control {
    background-color: white !important;
  }
}

.react-tel-input {
  width: 100% !important;
  border: none !important;

  .flag-dropdown {
    width: 80px !important;
    height: 40px !important;
    border: none !important;

    // &:hover {
    //   background-color: #f4f4f4 !important;
    // }

    &.open-dropdown,
    &:hover {
      background-color: #f4f4f4 !important;

      .selected-flag {
        background-color: #f4f4f4 !important;
      }
    }

    .selected-flag {
      padding-left: 20px !important;
      width: 100% !important;
      height: 100%;

      .flag {
        transform: scale(200%);
      }
    }
  }

  .form-control {
    background-color: #f4f4f4 !important;
    border-radius: 5px !important;
    height: 40px !important;
    font-size: 18px;
    border: none !important;
    // border-bottom: 1px solid color(line, 2) !important;
    padding-left: 80px !important;
    box-shadow: none !important;
  }
}

.ad-bg-container {
  position: relative;
  &::after {
    width: 100%;
    height: 100%;
    content: "";
    background: url(./images/bg.jpg);
    background-size: cover;
    // background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

:root {
  --color-bg: #fdf1f2;
  --color-heart: #ea442b;
  --easing: cubic-bezier(0.7, 0, 0.3, 1);
  --duration: 0.5s;
}

.like-button {
  position: relative;
  font-size: 30px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  border-radius: 50%;
  background: white;
  width: 1em;
  height: 1em;
  padding: 0;
  margin: 0;
  outline: none;
  z-index: 2;
  -webkit-transition: -webkit-transform var(--duration) var(--easing);
  transition: -webkit-transform var(--duration) var(--easing);
  transition: transform var(--duration) var(--easing);
  transition: transform var(--duration) var(--easing), -webkit-transform var(--duration) var(--easing);
  cursor: pointer;
}
.like-button:before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // box-shadow: 0 0.3em 0.6em rgba(0, 0, 0, 0.3);
  border-radius: inherit;
  -webkit-transition: inherit;
  transition: inherit;
}
.like-button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: inherit;
  z-index: -1;
}
.like-button:active:before {
  -webkit-animation: depress-shadow var(--duration) var(--easing) both;
          animation: depress-shadow var(--duration) var(--easing) both;
}
.like-button:focus:after {
  -webkit-animation: depress var(--duration) var(--easing) both;
          animation: depress var(--duration) var(--easing) both;
}
@-webkit-keyframes depress {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: translateY(5%) scale(0.9);
            transform: translateY(5%) scale(0.9);
  }
}
@keyframes depress {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: translateY(5%) scale(0.9);
            transform: translateY(5%) scale(0.9);
  }
}
@-webkit-keyframes depress-shadow {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}
@keyframes depress-shadow {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}
.like-wrapper {
  display: grid;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  z-index: 1;
}
.like-wrapper > * {
  margin: auto;
  grid-area: 1 / 1;
}

.heart {
  width: .5em;
  height: .5em;
  display: block;
  -webkit-transform-origin: center 80%;
          transform-origin: center 80%;
}
.heart > path {
  stroke: var(--color-heart);
  stroke-width: 2;
  fill: transparent;
  -webkit-transition: fill var(--duration) var(--easing);
  transition: fill var(--duration) var(--easing);
}
.like-button .heart > path {
  fill: var(--color-heart);
}
.like-button:focus .heart {
  -webkit-animation: heart-bounce var(--duration) var(--easing);
          animation: heart-bounce var(--duration) var(--easing);
}
@-webkit-keyframes heart-bounce {
  40% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  0%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes heart-bounce {
  40% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  0%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/* Added wrapper to prevent layout jank with resizing particles */
.particles {
  width: 1px;
  height: 1px;
}

.particle {
  position: absolute;
  top: 15px;
  left: 15px;
  height: .1em;
  width: .1em;
  border-radius: .05em;
  background-color: var(--color);
  --percentage: calc( var(--i) / var(--total-particles) );
  --Θ: calc( var(--percentage) * 1turn );
  -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0) scaleY(0);
          transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0) scaleY(0);
  -webkit-transition: all var(--duration) var(--easing);
  transition: all var(--duration) var(--easing);
}
.like-button:focus .particle {
  -webkit-animation: particles-out calc(var(--duration) * 1.2) var(--easing) forwards;
          animation: particles-out calc(var(--duration) * 1.2) var(--easing) forwards;
}
@-webkit-keyframes particles-out {
  50% {
    height: .3em;
  }
  50%, 60% {
    height: .3em;
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
            transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
  }
  60% {
    height: .2em;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
            transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
  }
}
@keyframes particles-out {
  50% {
    height: .3em;
  }
  50%, 60% {
    height: .3em;
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
            transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
  }
  60% {
    height: .2em;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
            transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
  }
}
.ripple {
  font-size: 30px;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}
.ripple:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // border: 0.4em solid var(--color-heart);
  border-radius: inherit;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.like-button:focus .ripple:before {
  -webkit-animation: ripple-out var(--duration) var(--easing);
          animation: ripple-out var(--duration) var(--easing);
}
@-webkit-keyframes ripple-out {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(5);
            transform: scale(5);
  }
}
@keyframes ripple-out {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(5);
            transform: scale(5);
  }
}
body {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  background-color: var(--color-bg);
}

/* Reset the animation when clicking again! */
.like-button:focus {
  pointer-events: none;
  cursor: normal;
}